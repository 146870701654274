.nav-menu {
    @apply
        absolute
        md:flex
        z-10
        bg-white
        rounded-r-md
        shadow-lg
        ring-1
        ring-black
        ring-opacity-5                    
        px-2        
        pt-1;
    top: 80px;        
    left: -300px;
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    &.open-mobile-menu {
        // @apply
        //     left-0;
            // bg-fujitransport;
        transform:translateX(300px);
        -webkit-transition: transform 0.25s ease;
        transition: transform 0.25s ease;
        // .nav-item {
        //     @apply
        //         text-white
        //         bg-fujitransport;
        // }
    }
}

.nav-item {
    @apply
        ml-0
        lg:ml-3
        flex
        justify-between
        items-center
        rounded-md
        bg-white
        text-base
        font-medium
        text-gray-700
        hover:bg-gray-50
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-offset-gray-100
        focus:ring-indigo-500
        p-2;
    span, a {
        line-height: 1.6;
    }
}

.dropdown {
    @apply
        cursor-pointer
        relative;
    // &:hover {
    //     .dropdown-menu {
    //         @apply
    //             hidden;
    //     }
    // }
}

.open-mobile-menu {
    .dropdown-menu {
        top: 0;
        right: 0;
    }
    .sub-dropdown-menu {
        .dropdown-menu-item {
            @apply
                text-white;
        }         
    }
}

.dropdown-menu {
    @apply
        origin-top-right
        relative
        right-0
        whitespace-nowrap
        rounded-md
        shadow-lg
        bg-fujitransport
        ring-1
        ring-black
        ring-opacity-5
        focus:outline-none
        z-10
        hidden;
    &.show {
        @apply
            block;
    }        
    .dropdown-menu-item {
        @apply
            text-white
            hover:bg-white
            hover:text-fujitransport
            text-left
            w-full
            block 
            px-4
            py-1
            text-base;  
        &.sub-dropdown {
            @apply
                relative;
            .sub-dropdown-menu {
                @apply
                    bg-white
                    py-1
                    absolute
                    top-0
                    block
                    rounded-md
                    shadow-lg
                    ring-1
                    ring-black
                    ring-opacity-5
                    whitespace-nowrap
                    hidden;       
                // left: -8px;                                                    
                left: 104%;      
                .dropdown-menu-item {
                    @apply
                        text-gray-700
                        hover:bg-fujitransport
                        hover:text-white
                }                                             
                &.show {
                    @apply
                        block;
                }
            }
            // &:hover {
            //     .sub-dropdown-menu {
            //         @apply
            //             block
            //             text-gray-600
            //             bg-white;
            //     }    
            // }        
        }
    }
}

@media(min-width: 768px) {
    .nav-menu {
        @apply
            ring-0
            shadow-none
            static;
        .open-mobile-menu {
        @apply
            shadow-none;
        }
    }
    .nav-item {
        @apply
            text-xs;
    }
    .dropdown {
        @apply
            relative;
        &:hover {
            .dropdown-menu {
                @apply
                    block;
            }
        }
        .dropdown-menu {
            @apply
                hidden
                bg-white
                absolute;
            .dropdown-menu-item {
                @apply
                    text-gray-700
                    hover:bg-fujitransport
                    hover:text-white
                    text-xs;  
                &.sub-dropdown {
                    @apply
                        relative;                    
                    .sub-dropdown-menu {
                        @apply
                            absolute
                            hidden
                            left-full;
                        top: -4px;                         
                    }
                    &:hover {
                        .sub-dropdown-menu {
                            @apply
                                block;
                        }    
                    }                       
                }
            }
        }
    }    
}